
/**
 * Dashboard card container regularly use to show the donut chart or but it can recive other slot to render other uimportant
 * information about the services
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import HealthChart from "@/views/charts/HealthChart.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DashboardItem",
  components: {
    HealthChart
  }
})
export default class DashboardItem extends Vue {
  /*----------  Props  ----------*/
  @Prop({ type: String, default: "" }) dashboardItemLabel!: string;
  @Prop({ type: String, default: "" }) cardExtraClass!: string;
  @Prop({ type: Number, default: 0 }) dashboardItemLabelValue!: number;
  @Prop({ type: Object, default: () => {} }) dashboardItemData!: Record<string, number>;
  @Prop({ type: Number, default: 200 }) dashboardItemDataheight!: number[];
  @Prop({ type: Array, default: () => [] }) dashboardItemDataColors!: number[];
  @Prop({ type: String, default: "right" }) dashboardItemDataLabelPosition!: string;
  @Prop({ type: Number, default: 4 }) lg!: number;
  @Prop({ type: Number, default: 4 }) cardHeaderMb!: number;
  @Prop({ type: Number, default: 3 }) cardHeaderPY!: number;
  @Prop({ type: Boolean, default: true }) minHeight!: boolean;
}
